.terminal {
  width: 650px;
  height: 350px;
  position: absolute;
  top: 20px;
  left: 20px;
  background: black;
  color: rgb(0, 255, 0);
  resize: none;
  outline: none;
  border: 1px solid rgb(0, 255, 0);
  padding: 10px;
  border-top: 20px solid rgb(0, 255, 0);
  cursor: auto;
}

.terminalAuto {
  width: 500px;
  height: 350px;
  top: 450px;
  left: 20px;
}

.terminal::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 975px) {
  .terminal {
    display: none;
  }
}
