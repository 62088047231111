.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.globe {
  width: 200px;
}

.ilyahacker {
  width: 600px;
  top: 20px;
  left: 800px;
  background: #053972;
  position: absolute;
  border: 1px solid #053972;
  border-top: 20px solid #053972;
}

.footerMobile {
  display: none;
}

@media only screen and (max-width: 975px) {
  .app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    padding: 2px;
  }
  .globe {
    display: none;
  }

  .ilyahacker {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }

  .footerMobile {
    display: block;
  }

  .footerDesktop {
    display: none;
  }
}
