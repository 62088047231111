.projectsWrapper {
  position: absolute;
  width: 400px;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #053972;
}

.projectsBorderTop {
  height: 20px;
  background: #053972;
}

.projects {
  background: black;
  color: #053972;
  font-size: 30px;
  font-weight: 900;
}

.projectsText {
  user-select: none;
  text-align: center;
  border-bottom: 1px solid #053972;
  padding: 10px;
  color: #053972;
}

.projectsList {
  display: flex;
  flex-wrap: wrap;
}

.project {
  width: 100%;
  height: 100px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #053972;
  border-bottom: 1px #053972 solid;
}

.project:last-child {
  border-bottom: none;
}

.project:hover {
  background: #053972;
  color: black;
  cursor: pointer;
}

@media only screen and (max-width: 975px) {
  body {
    overflow: visible;
  }

  .projectsWrapper {
    margin-top: 10px;
    width: 100%;
    position: relative;
    right: 0px;
    top: 0px;
  }
}
