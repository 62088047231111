.footer {
  padding: 5px 0px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25px;
  background: rgb(0, 255, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.contacts,
.features {
  display: flex;
}

.link {
  border-radius: 2px;
  margin-right: 5px;
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
  border: 1px solid black;
  color: black;
  transition: 0.2s all ease;
}

.link:first-child {
  margin-left: 5px;
}

@media only screen and (max-width: 975px) {
  .footer {
    margin-top: 5px;
    position: relative;
    background: transparent;
  }

  .contacts {
    width: 100%;
    justify-content: space-around;
  }

  .link {
    border-radius: 20px;
    width: 100%;
    text-align: center;
  }

  .features {
    display: none;
  }
}
