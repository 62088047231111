.hackingFbi {
  color: rgb(241, 0, 0);
  position: absolute;
  top: 700px;
  left: 700px;
  font-size: 30px;
  background: black;
  border: 1px solid rgb(0, 255, 0);
  padding: 10px;
  border-top: 20px solid rgb(0, 255, 0);
  user-select: none;
}

.accessGranted {
  text-align: center;
  color: rgb(0, 255, 0);
  font-size: 50px;
}

.hackingFbiText {
  text-align: center;
  user-select: none;
}

@media only screen and (max-width: 975px) {
  .hackingFbi {
    display: none;
  }
}
